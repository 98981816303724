import styled from "styled-components"
import Img from "gatsby-image"

const Component = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  padding: 2rem 1rem 2rem 0;
  margin-bottom: 1rem;
  border-bottom: 2px solid #f2f2f2;
  align-items: center;
  justify-items: center;
`
export const Cover = styled.img`
  border-radius: 10px;
  margin-right: 1rem;
  height: 12rem;
`
export default Component
